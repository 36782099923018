import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";


const Home = () => {
    return(
        <div id="container">
                <video width="100%" height="100%" autoPlay muted loop>
                    <source src="/video/slide1.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
        </div>
    );
};

export default Home;